import './Review.scss'
const Review = () => {
  let random = Math.floor(Math.random()*6)

  let quotes = ["Coding makes a man perfect","Imagination is the power of creativity","Skills pays you bills",
  "If you don't understand recursion read this again!","There's no place like 127.0.0.1","Think twice, Code once"]
  const data = [
    {
      id: 1,
      title: "HTML/CSS",
      img:
        "https://miro.medium.com/max/792/1*lJ32Bl-lHWmNMUSiSq17gQ.png",
      icon: "assets/twitter.png",
      desc:
        "I like HTML/CSS very much because it can satisfy my creative mind",
        link:"https://html.com/"
    },
    {
      id: 2,
      title: "Vanilla JS",
      img:
        "https://miro.medium.com/proxy/0*UzaG_alYU6vQ8MIt.jpg",
      icon: "assets/youtube.png",
      desc:
        "I like vanilla JS, it used for many pleasent actions and it has cool behaviour's",
        link:"https://www.javascript.com/"
    },
    {
      id: 3,
      title: "Node JS",
      img:
        "https://the-guild.dev/blog-assets/nodejs-esm/nodejs_logo.png",
      icon: "assets/linkedin.png",
      desc:
        "I like NodeJs , It used to connect the database like my-sql and it used to create lot of bussiness Projects",
        link:"https://nodejs.org/en/"
    },
    {
      id: 4,
      title: "React JS",
      img:
        "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a7/React-icon.svg/1200px-React-icon.svg.png",
      icon: "assets/twitter.png",
      desc:
        "Currently I am learning Reactjs It has many cool functions and it can use to create reusable components :)",
        link:"https://reactjs.org/"
    },
  ];
  return (
    <div className="review" id="skills">
      <h1 className='skills'>Skills</h1>
      <div className="container">
        {data.map((d) => (
          <div className={d.featured ? "card featured" : "card"}>
            <div className="top">
              <img
                className="user"
                src={d.img}
                alt=""
              />
            </div>
          
            <div className="bottom">
              <h3>{d.name}</h3>
              <a target="_blank" href={d.link}><h4>{d.title}</h4></a>
            </div>
          </div>
        ))}
        <h2 className="quote"><span className="star">” </span>{quotes[random]}<span className="star"> ”</span></h2>        
      </div>
    </div>
  );
}
export default Review