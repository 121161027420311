import { useState } from "react";
import "./app.scss";
import Contact from "./components/contact/Contact";
import Intro from "./components/intro/Intro";
import Portfolio from "./components/portfollio/Portfolio";
import Review from "./components/review/Review";
import Topbar from "./components/topbar/Topbar";
import Works from "./components/works/Works";
import Menu from "./components/menu/Menu";
import Logo from "./components/logo/Logo";

function App() {
  const [menuOpen, setMenuOpen] = useState(true);
  const [done, setDone] = useState(false);

  setTimeout(() => {
    setDone(true);
  }, 6000);
  
  return (
    <>
      {!done ? (
        <div className="load">
          <Logo />
        </div>
      ) : (
        <div id="app" className="app">
          <Topbar menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
          <Menu menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
          <div className="sections">
            <Intro />
            <Portfolio />
            <Works />
            <Review />
            <Contact />
          </div>
        </div>
      )}
    </>
  );
}

export default App;
