import './menu.scss'
import {Person,Home,Phone,Work,VideoLabel,PersonPin} from '@material-ui/icons'


const Menu = ({menuOpen,setMenuOpen}) => {
  return (
    <div className={"menu "+(menuOpen && "active")}>
        <ul>
            <li onClick={()=>setMenuOpen(!menuOpen)}><a href="#intro"><Home className='sideicon' /></a></li>
            <li onClick={()=>setMenuOpen(!menuOpen)}><a href="#portfolio"><Work className='sideicon'/></a></li>
            <li onClick={()=>setMenuOpen(!menuOpen)}><a href="#works"><VideoLabel className='sideicon'/></a></li>
            <li onClick={()=>setMenuOpen(!menuOpen)}><a href="#skills"><PersonPin className='sideicon'/></a></li>
            <li onClick={()=>setMenuOpen(!menuOpen)}><a href="#contact"><Phone className='sideicon' /></a></li>            
        </ul>
    </div>
  )
}

export default Menu