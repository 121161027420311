import "./intro.scss"
import {init} from 'ityped'
import { useEffect , useRef} from "react"
import { useState } from "react"
import Axios from 'axios';
const Intro = () => {
  const textRef = useRef()

  
  useEffect(()=>{
    init(textRef.current,{
      showCursor:true,
      backDelay:1500,
      backSpeed:60,
      strings:["Developer","Designer"]
    })
  },[])

  return (
    
    <div className="intro" id="intro">
    <div className="left">
      <div className="imgContainer">
        <img src="assets/person.png" alt="" />
      </div>
    </div>
    <div className="right">
      <div className="wrapper">
        <h2>Hi There, I'm</h2>
        <h1>Hariharan V.S</h1>
        <h3>
          Frontend <span ref={textRef}></span>
        </h3>
        <p className="infos">I have serious passion for UI/UX effects</p>
      </div>
      <a href="#portfolio">
        <img src="assets/down.png" alt="" />
      </a>
    </div>
  </div>
  )
}

export default Intro