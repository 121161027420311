import './topbar.scss'
import {Person,Mail,Phone} from '@material-ui/icons'

const Topbar = ({menuOpen,setMenuOpen}) => {
  return (
    <div className={'topbar ' + "active"}>
      <div className="wrapper">
        <div className="left">
          <div className="itemContainer">
            <Phone className='icon' />
            <span>+91 79049 06675</span>
          </div>
          <div className="itemContainer">
            <Mail className='icon' />
            <span>hari.vs@zohocorp.com</span>
          </div>
          <div className="itemContainer">
            <Person className='icon' />
            <span>ZS-ID: ZSTK262</span>
          </div>
        </div>
  
        <div className="right">
        <a  href="#intro" className='logo'>Hari</a>

        </div>
      </div>
    </div>
  )
}

export default Topbar