export const AllPortfolio = [{
        id: 1,
        title: "Notes App (Nodejs-Mysql)",
        img: "assets/notes.png",
        url: "https://github.com/vs182/notes-nodejs.git"
    },
    {
        id: 2,
        title: "Weather App (Reactjs)",
        img: "assets/whether.jpeg",
        url: "https://weatherzoho.netlify.app/"

    },
    {
        id: 3,
        title: "Chat App (Socket.js)",
        img: "assets/chat.jpeg",
        url: "https://github.com/vs182/chat-socket.io.git"

    },
    {
        id: 4,
        title: "Online Notepad",
        img: "assets/online_note.png",
        url: "https://onlinenotepadzoho.netlify.app/"
    },
    {
        id: 5,
        title: "Learning App (Nodejs-Mysql)",
        img: "assets/drop.png",
        url: "https://github.com/vs182/learn-app.git"

    },
    {
        id: 6,
        title: "Tripy (Vanilla Js)",
        img: "assets/tripy.avif",
        url: "https://github.com/vs182/Tripy-final.git"

    },
];

export const webPortfolio = [{
        id: 1,
        title: "Notes App (Nodejs-Mysql)",
        img: "assets/notes.png",
        url: "https://github.com/vs182/notes-nodejs.git"


    },
    {
        id: 2,
        title: "Chat App (Socket.js)",
        img: "assets/chat.jpeg",
        url: "https://github.com/vs182/chat-socket.io.git"

    },
    {
        id: 3,
        title: "Learning App (Nodejs-Mysql)",
        img: "assets/drop.png",
        url: "https://github.com/vs182/learn-app.git"

    },
    {
        id: 4,
        title: "Memory Game (Vanilla Js)",
        img: "assets/memory.png",
        url: "https://memorygamezoho.netlify.app/"

    },
    {
        id: 5,
        title: "Tripy (Vanilla Js)",
        img: "assets/tripy.avif",
        url: "https://github.com/vs182/Tripy-final.git"

    },
];

export const mobilePortfolio = [{
        id: 1,
        title: "Weather App (Reactjs)",
        img: "assets/whether.jpeg",
        url: "https://weatherzoho.netlify.app/"
    },
    {
        id: 2,
        title: "Online Notepad",
        img: "assets/online_note.png",
        url: "https://onlinenotepadzoho.netlify.app/"
    },
    {
        id: 3,
        title: "Card Gambling Game",
        img: "assets/cards.jpeg",
        url: "https://github.com/vs182/card-game.git"
    },
    {
        id: 4,
        title: "E-commerce",
        img: "assets/cart.png",
        url: "https://github.com/vs182/dashboard.git"
    },
    {
        id: 5,
        title: "Type Swift",
        img: "assets/type.png",
        url: "https://github.com/vs182/keyboard.git"
    },
];

export const designPortfolio = [{
        id: 1,
        title: "Card Gambling Game",
        img: "assets/cards.jpeg",
        url: "https://www.figma.com/file/q1Rpm39FBEU7WbxKRNYQN8/Portfollio"
    },
    {
        id: 2,
        title: "E-commerce UI",
        img: "assets/cart.png",
        url: "https://www.figma.com/file/ZEEVxfJRRpztoC2dWqhklP/React-Components"
    },
    {
        id: 3,
        title: "Learning App",
        img: "assets/drop.png",
        url: "https://www.figma.com/file/zZBX6wxxxVzw3DrrekMD52/figma"
    },
    {
        id: 4,
        title: "Landing Page",
        img: "assets/landing.webp",
        url: "https://www.figma.com/proto/Ct8mOVefztAGHGRGQflGFB/landing?node-id=0%3A1"
    }
];

export const DocsPortfolio = [{
        id: 1,
        title: "Eidetic-Figma",
        img: "https://userguiding.com/wp-content/uploads/2021/02/best-figma-plugings.png",
        url: "https://ambloxx-ionic.blogspot.com/2022/03/eidetic-figma.html"
    },
    {
        id: 2,
        title: "Github",
        img: "https://foundations.projectpythia.org/_images/GitHub-logo.png",
        url: "https://ambloxx-ionic.blogspot.com/2022/03/github.html"
    },
    {
        id: 3,
        title: "Netlify",
        img: "https://mma.prnewswire.com/media/1099201/Netlify_Logo.jpg?p=facebook",
        url: "https://ambloxx-ionic.blogspot.com/2022/03/is-awesome-and-perfect-site-for-hosting.html"
    },
];